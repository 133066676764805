import Result from 'crocks/Result';
import { array, boolean, is, number, type, string, union, lazy, Infer } from 'superstruct';
import { OnlineMag, parseJson } from './utils';
import { Menu } from './components/Menu';
import { COLOURS } from './constants';

import type {
  MenuSettings,
  MenuSubMenu,
  MenuButton,
  MenuLink,
  MenuItem,
  MenuOptions,
  MenuItems
} from './components/Menu';
import type { ToolbarLink } from './@types/widgets';


// =================
// CONSTANTS
// =================

const TRANSPARENT = 'transparent';
const LEGACY_MENU = 'LEGACY_MENU';
const MENU = 'MENU';
const NO_MENU = 'NO_MENU';
const UPDATE_MENU_EVENT = 'UPDATE_MENU_EVENT';

// REMOVE `document.createEvent` AND `updateMenuEvent.initEvent` once IE11 support have been dropped.
// @ts-ignore
const isIE11 = Boolean(window.MSInputMethodContext) && Boolean(document.documentMode);

var updateMenuEvent = document.createEvent('Event');

// Define that the event name is 'build'.
updateMenuEvent.initEvent(UPDATE_MENU_EVENT, true, true);

if (!isIE11) {
  updateMenuEvent = new Event(UPDATE_MENU_EVENT);
}


// ===================
// INCOMING DATA TYPES
// ===================

const MenuLinkValidator = type({
  backgroundColour: string(),
  backgroundHoverColour: string(),
  id: number(),
  target: string(),
  text: string(),
  url: string()
});

const MenuButtonValidator = type({
  backgroundColour: string(),
  backgroundHoverColour: string(),
  id: number(),
  text: string(),
  javascript: string()
});

const MenuSubMenuValidator = type({
  backgroundColour: string(),
  backgroundHoverColour: string(),
  id: number(),
  subMenus: lazy(() => array(MenuUnionValidator)),
  text: string()
});

const MenuUnionValidator = union([MenuLinkValidator, MenuButtonValidator, MenuSubMenuValidator]);

const MenuSettingsValidator = type({
  backgroundColour: string(),
  backgroundHoverColour: string(),
  horizontalSeperator: boolean(),
  seperatorColour: string(),
  subMenus: array(MenuSubMenuValidator),
  submenuArrows: boolean(),
  textColour: string(),
  verticalSeperator: boolean()
});

type MenuLinkValidator = Infer<typeof MenuLinkValidator>;
type MenuButtonValidator = Infer<typeof MenuButtonValidator>;
type MenuSubMenuValidator = Infer<typeof MenuSubMenuValidator>;
type MenuUnionValidator = Infer<typeof MenuUnionValidator>;
type MenuSettingsValidator = Infer<typeof MenuSettingsValidator>;


// ===================
// MENU DATA TYPES
// ===================

type LegacyMenu = {
  _brand: typeof LEGACY_MENU;
  menuHtml: string;
  menuJsCss: string;
  showLogo: boolean;
  accountMenu: MenuAccount;
};

type Menu = {
  _brand: typeof MENU;
} & MenuOptions;

type NoMenu = {
  _brand: typeof NO_MENU;
};

export type MenuVersion = LegacyMenu | Menu | NoMenu;

type MenuResult =
  | {
      status: 'OK';
      data: Menu;
    }
  | { status: 'ERR'; error: Error };

/**
 * Creates a no menu data type
 */
function createNoMenu(): NoMenu {
  return {
    _brand: NO_MENU
  };
}

// =================
// DATA TRANSFORMERS
// =================

function defaultEmptyStringTo(defaultValue: string) {
  return function (value: string) {
    return value === '' ? defaultValue : value;
  };
}

const defaultToTransparent = defaultEmptyStringTo(TRANSPARENT);
const defaultToBlack = defaultEmptyStringTo(COLOURS.BLACK);

function mapUnknownMenuItemToMenuItem(genericUnknownType: MenuUnionValidator): MenuItem {
  if (genericUnknownType.url) {
    return createMenuLink(genericUnknownType);
  }

  if (genericUnknownType.javascript) {
    return createMenuButton(genericUnknownType);
  }

  return createSubMenu(genericUnknownType);
}

function createMenuLink(genericLink: MenuLinkValidator): MenuLink {
  return {
    _brand: 'link',
    href: genericLink.url,
    target: genericLink.target as '' | '_blank',
    backgroundColour: defaultToTransparent(genericLink.backgroundColour),
    backgroundHoverColour: defaultToTransparent(genericLink.backgroundHoverColour),
    id: genericLink.id,
    text: genericLink.text
  };
}

function createMenuButton(genericButton: MenuButtonValidator): MenuButton {
  const [action, actionArg = ''] = genericButton.javascript.split('|');

  return {
    _brand: 'button',
    action,
    actionArg,
    backgroundColour: defaultToTransparent(genericButton.backgroundColour),
    backgroundHoverColour: defaultToTransparent(genericButton.backgroundHoverColour),
    id: genericButton.id,
    text: genericButton.text
  };
}

function createSubMenu(genericSubMenu: MenuSubMenuValidator): MenuSubMenu {
  return {
    _brand: 'submenu',
    items: genericSubMenu.subMenus.map(mapUnknownMenuItemToMenuItem),
    backgroundColour: defaultToTransparent(genericSubMenu.backgroundColour),
    backgroundHoverColour: defaultToTransparent(genericSubMenu.backgroundHoverColour),
    id: genericSubMenu.id,
    text: genericSubMenu.text
  };
}

function parseMenuJson(menuJson: string): MenuResult {
  return parseJson(menuJson)
    .chain((data: unknown) => {
      if (is(data, MenuSettingsValidator)) {
        return Result.Ok(data);
      }

      return Result.Err('Menu JSON was invalid');
    })
    .map((menuData: MenuSettingsValidator) => ({
      ...menuData,
      subMenus: mapUnknownMenuItemToMenuItem(menuData)
    }))
    .either(
      (error: string) => {
        reportError(error);

        return { status: 'ERR', error };
      },
      (menu: Menu) => {
        return {
          status: 'OK',
          data: menu
        };
      }
    );
}

/**
 *
 */
function getMenuVersion({
  menuHtml,
  menuJsCss,
  menuJson,
  showLogo,
  accountMenu,
  forceLegacyMenu
}: {
  menuHtml: string;
  menuJsCss: string;
  menuJson: string;
  showLogo: boolean;
  accountMenu: MenuAccount;
  forceLegacyMenu: boolean;
}): MenuVersion {

  if (!menuHtml && !menuJson) {
    return createNoMenu();
  }



  if (menuHtml && forceLegacyMenu) {
    return {
      _brand: LEGACY_MENU,
      menuHtml,
      menuJsCss,
      showLogo,
      accountMenu
    };
  }

  return parseJson(menuJson)
    .map((menuData: MenuSettingsValidator) => ({
      _brand: MENU,
      ...menuData,
      accountMenu,
      backgroundColour: defaultToBlack(menuData.backgroundColour),
      backgroundHoverColour: defaultToBlack(menuData.backgroundHoverColour),
      items: menuData.subMenus.map(mapUnknownMenuItemToMenuItem),
      showLogo: showLogo
    }))
    .either(createNoMenu, (menu: Menu) => menu);
}

function isLegacy(menu: MenuVersion): menu is LegacyMenu {
  return menu._brand === LEGACY_MENU;
}

function isMenu(menu: MenuVersion): menu is Menu {
  return menu._brand === MENU;
}

/**
 *
 * @param menuItems
 * @param parentBackgroundColour
 * @param parentBackgroundHoverColour
 * @returns
 */
 function parseMenuItemCss(menuItems: MenuItems, parentBackgroundColour: string, parentBackgroundHoverColour: string) {     
  let items = [];
  
  for (let item of menuItems) {
    
    let { backgroundColour = "transparent", backgroundHoverColour = "transparent" } = item || {};
            
    backgroundColour = backgroundColour !== "transparent" ? backgroundColour : parentBackgroundColour;
    backgroundHoverColour = backgroundHoverColour !== "transparent" ? backgroundHoverColour : (parentBackgroundHoverColour !== "transparent" ? parentBackgroundHoverColour : backgroundColour );

    items = [...items, {
      id: item.id,
      name: item.text,
      type: item._brand,
      backgroundColour,
      backgroundHoverColour,
    }];
    
    if (item._brand === "submenu" && item.items) {
      const subItems = parseMenuItemCss(item.items, backgroundColour, backgroundHoverColour );
      items = [...items, ...subItems];      
    }
  }
  
  return items;
}


export function renderMenuStyles(menuSettings: MenuSettings, menuItems: MenuItems): string {
  const menuItemStyles = parseMenuItemCss(menuItems, menuSettings.backgroundColour, menuSettings.backgroundHoverColour);
  
  let menuItemCSS = ``;
  for (const menuItemStyle of menuItemStyles) {    
    const { id, type, backgroundColour, backgroundHoverColour } = menuItemStyle;
        
    menuItemCSS = `
      ${menuItemCSS}
      #menu-${id} {
        background-color: ${backgroundColour};
      }
      #menu-${id}:focus${type === "submenu" ? `-within` : ``},
      #menu-${id}:hover {
        background-color: ${backgroundHoverColour};
      }
    `;
  }
  
  return `
    .nav-container {
      background-color: ${menuSettings.backgroundColour};
      color: ${menuSettings.textColour};
    }

    .pt-nav-mobile-menu-text {
      color: ${menuSettings.textColour};
    }

    .pt-nav-mobile-menu-button:focus-visible,
    .pt-nav-mobile-close-button:focus-visible {
      outline-color: ${menuSettings.textColour};
    }

    .pt-nav-list {
      background-color: ${menuSettings.backgroundColour};
    }

    .pt-nav-item-link,
    .pt-nav-item-button {
      color: ${menuSettings.textColour};
    }

    .pt-nav-list-item .pt-nav-item-link:focus-visible,
    .pt-nav-list-item .pt-nav-item-button:focus-visible {
      outline-color: ${menuSettings.textColour};
    }

    ${menuItemCSS}

    ${
      menuSettings.verticalSeperator
        ? `.pt-nav-list-item:not(.mod-level-0) {
            box-shadow: 0 -1px 0 ${menuSettings.seperatorColour} inset;
          }`
        : ``
    }

    ${
      menuSettings.horizontalSeperator
        ? `
          .pt-nav-desktop .pt-nav-list-item.mod-level-0 {
            box-shadow: 1px 0 0 ${menuSettings.seperatorColour};
            margin-right: 1px;
          }

          .pt-nav-desktop .pt-nav-list-item.mod-level-1,
          .pt-nav-desktop .pt-nav-list-item.mod-level-2 {
            box-shadow: 0 1px 0 ${menuSettings.seperatorColour};
            margin-bottom: 1px;
          }

          .pt-nav-mobile .pt-nav-list-item {
            box-shadow: 0 -1px 0 ${menuSettings.seperatorColour};
            margin-top: 1px;
          }`
        : ``
    }

    .pt-nav-item-button[aria-expanded='true'],
    .pt-nav-item-button:focus,
    .pt-nav-item-button:hover,
    .pt-nav-item-link:focus,
    .pt-nav-item-link:hover {
      background-color: ${menuSettings.backgroundHoverColour};
    }`;
}



// =================
// MENU ACCOUNT
// =================

export type MenuAccount = Account | null;

type Account = {
  _brand: 'account';
  dropdowns: ToolbarLink[];
};

/**
 *
 * @param authPath the start of the auth URL eg. view.pagetiger-dev.com/Reader
 * @param onlineMagID the ID of the current mag
 * @returns
 */
function createMenuAccount(authPath: string, onlineMagID: number): Account {
  const currentUrl = encodeURIComponent(window.location.href);

  return {
    _brand: 'account',
    dropdowns: [
      {
        type: 'link',
        extraClasses: '',
        href: `${authPath}MyAccount/Default.aspx?MID=${onlineMagID}&redirecturl=${currentUrl}&TI=0`,
        text: 'My Account',
        newWindow: false,
        svgID: ''
      },
      {
        type: 'link',
        extraClasses: '',
        href: `${authPath}Logout/Default.aspx?MID=${onlineMagID}&redirecturl=${currentUrl}`,
        text: 'Logout',
        newWindow: false,
        svgID: ''
      }
    ]
  };
}

function isMenuAccount(menuAccount: MenuAccount): menuAccount is Account {
  return menuAccount._brand === 'account';
}

function getMenuAccount(
  hasAuthRedirect: boolean,
  authPath: string,
  onlineMagID: number,
  showAccountInMenu: boolean
): MenuAccount {
  return hasAuthRedirect && showAccountInMenu ? createMenuAccount(authPath, onlineMagID) : null;
}

/**
 * Fetches the latest Menu JSON structure. This should happen only once there has been a permissions
 * change (e.g. a badge has been awarded) and there addition menu items may be present
 */
function fetchMenuItems(docGuid: OnlineMag['guid']): Promise<MenuItems> {
  return fetch(`/api/v1/menu?IGUID=${docGuid}`, {
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(res => (res.ok ? res.json() : Promise.reject(res)))
    .then(menuJSON => menuJSON.subMenus.map(mapUnknownMenuItemToMenuItem));
}

export {
  isLegacy,
  isMenu,
  isMenuAccount,
  getMenuAccount,
  getMenuVersion,
  parseMenuJson,
  fetchMenuItems,
  UPDATE_MENU_EVENT,
  updateMenuEvent
};
