import { object, string, array, Infer, is, number, boolean } from 'superstruct';
import { htmlEncode, forceReload, getI18nByKey } from './utils';
import type { OnlineMag } from './@types/pagetiger';

/** Types  */

const matches = object({
  exact: boolean(),
  value: string()
});

const SearchResult = object({
  content: string(),
  matches: array(matches),
  onlineMagPageID: number(),
  pageIndex: number(),
  pageThumbnailUrl: string(),
  pageTitle: string()
});

const SearchResults = array(SearchResult);

export type SearchResults = Infer<typeof SearchResults>;

/** Functions */

/**
 * Take the parsed JSON and attempt to map it to our search results type
 */
const parseSearchResults = (searchResults: unknown): Promise<SearchResults> => {
  if (is(searchResults, SearchResults)) {
    return Promise.resolve(searchResults);
  }

  return Promise.reject('Unvalid Search Request');
};

/**
 * Reformat Page 0001 to Page 1, is the page title is in the format of Page [0-9]{,4}
 */
const formatPageTitle = (rawPageTitle: string, pageNumber: number): string => {
  // Define the regular expression to match "Page" followed by one or more spaces and then 1 to 4 digits.
  // The regex is anchored to ensure that "Page N" must be the entire string.
  const pageNumberRegex = /^Page\s+([0-9]{1,4})$/i;

  // Execute the regex to test if rawPageTitle matches the pattern
  const match = pageNumberRegex.exec(rawPageTitle);

  // If a match is found and the matched number corresponds to the pageNumber
  if (match && parseInt(match[1], 10) === pageNumber) {
    // Return the formatted page title with the given pageNumber
    return `Page ${pageNumber}`;
  }

  // If it does not match or numbers don't correspond, return the original page title
  return rawPageTitle;
};


/**
 * Take an array of search results and returns the string based mark-up.
 */
const getSearchResultsMarkup = (pageTurn: OnlineMag, searchResults: SearchResults): string =>
  searchResults
    .map(item => {
      const { thumbnailHeight, thumbnailWidth } = pageTurn;
      const heightAndWidthStyle = `height: ${thumbnailHeight}px; width: ${thumbnailWidth}px; `;
      const { content, pageThumbnailUrl, pageIndex, pageTitle } = item;
      const displayNumber = (pageIndex + 1).toString();
      const formattedPageTitle = formatPageTitle(pageTitle, pageIndex + 1);

      return `
        <div class="search-result-item" data-t="search-result">
          <div class="search-image" aria-hidden="true">
            <a tabindex="-1" aria-hidden="true" href="javascript: ptiCloseSearchGotoPage('', ${pageIndex});">
              <div style="border: 1px solid #cbcbcb; background: url(${htmlEncode(
                pageThumbnailUrl
              )}); ${heightAndWidthStyle}"><span class="visually-hidden">Page ${pageIndex}</span></div>
            </a>
            <span class="search-image-number">${getI18nByKey('Page')} ${htmlEncode(displayNumber)}</span>
          </div>

          <div>
            <a
              href="javascript: ptiCloseSearchGotoPage('', ${pageIndex});"
              class="search-link"
              data-t="search-page-${pageIndex}">
                ${htmlEncode(formattedPageTitle)}
            </a>

            <p class="search-text">${content}</p>
          </div>
        </div>`;
    })
    .join('');

const getSearchResults = (pageTurn: OnlineMag, searchTerm: string): Promise<SearchResults> => {
  const { guid, host, proofingToken } = pageTurn;
  const { referralUrl } = pageTurn.config;
  const errorMessage = 'There was a problem searching this document.';
  const url = new URL(`https://${host}/api/v1/search`);

  url.searchParams.set('Q', encodeURIComponent(searchTerm));
  url.searchParams.set('PT', proofingToken);
  url.searchParams.set('HN', document.location.hostname);
  url.searchParams.set('RU', referralUrl);
  url.searchParams.set('IGUID', guid);

  return fetch(url.href, {
    method: 'GET',
    credentials: 'include',
    mode: 'cors'
  })
    .then(res => {
      if (res.status === 401) {
        forceReload();
        return Promise.reject();
      }

      if (res.ok) {
        return res;
      }

      return Promise.reject(errorMessage);
    })
    .then(res => res.json())
    .then(parseSearchResults);
};

export { getSearchResults, getSearchResultsMarkup };
